const weekdayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function getDayWithSuffix(day) {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = day % 100;
    return `${day}${suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]}`;
}

function padZero(num) {
    return num < 10 ? `0${num}` : num;
}

// Convert datetime to readable format, i.e."2023-03-26T13:51:44.702Z" to "Sunday, March 26th 2023 13:51"
export function formatDate(datetime) {

    const inputDate = new Date(datetime);

    const offsetInMinutes = inputDate.getTimezoneOffset();
    const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
    const localDate = new Date(inputDate.getTime() - offsetInMilliseconds);

    const weekdayIndex = localDate.getUTCDay();
    const dayOfMonth = localDate.getUTCDate();
    const monthIndex = localDate.getUTCMonth();
    const year = localDate.getUTCFullYear();
    const hours = localDate.getUTCHours();
    const minutes = localDate.getUTCMinutes();

    const dateString = `${weekdayNames[weekdayIndex]}, ${monthNames[monthIndex]} ${getDayWithSuffix(dayOfMonth)} ${year} ${padZero(hours)}:${padZero(minutes)}`;

    return dateString;
};

export function formatDateShort(datetime) {

    const inputDate = new Date(datetime);

    const offsetInMinutes = inputDate.getTimezoneOffset();
    const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
    const localDate = new Date(inputDate.getTime() - offsetInMilliseconds);

    const day = localDate.getDate().toString().padStart(2, '0');
    const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
    const year = localDate.getFullYear().toString();
    const hours = localDate.getHours().toString().padStart(2, '0');
    const minutes = localDate.getMinutes().toString().padStart(2, '0');

    const dateString = `${day}/${month}/${year} ${hours}:${minutes}`;

    return dateString;
};