import ContentCard from '../../components/ContentCard/ContentCard';
import PageTitle from '../../components/PageTitle/PageTitle'
import './Home.scss'
import { Stack, Col, Row, Container, Button } from 'react-bootstrap';
import Tag from '../../components/Tag/Tag';
import { tagSort } from '../../utils/tagUtils';
import { useSelector, useDispatch } from 'react-redux';
import { fetchStoriesAsync } from '../../store/storiesSlice';
import { fetchCharactersAsync } from '../../store/charactersSlice';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import defaultProfilePic from '../../assets/images/default-profile-icon.jpg'

function Home() {

    const apiUrl = process.env.REACT_APP_API_URL;

    const storiesData = useSelector(state => state.stories);
    const charactersData = useSelector(state => state.characters);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchStoriesAsync());
        dispatch(fetchCharactersAsync());
        document.title = `Home | Ardeo's Doghouse`;
    }, [dispatch]);

    const getTags = (tags) => {

        if (tags) {
            const filteredAndSortedTags = [...tags].filter((tag) => tag.type !== "None").sort(tagSort);
            return filteredAndSortedTags.map((tag) => (
                <Tag key={tag.guid} content={tag.name} type={tag.type.toLowerCase()} />))
        } else
            return null;
    }

    const handleReadStory = (guid, newTab) => {
        if (newTab) {
            window.open(`${window.location.origin}/stories/${guid}`, "_blank", "noopener,noreferrer");
            return;
        }

        navigate(`/stories/${guid}`);
    }

    const handleReadBio = (guid, newTab) => {
        if (newTab) {
            window.open(`${window.location.origin}/characters/${guid}`, "_blank", "noopener,noreferrer");
            return;
        }
        navigate(`/characters/${guid}`);
    }

    const getAppearsIn = (stories) => {
        return (<Container className={"appears-in-container"}>
            <Row>
                <Col>
                    <span>Appears In</span>
                    <ul>
                        {stories?.map((story) => {
                            return <li key={`appears-in-${story.guid}`}><a href={`/stories/${story.guid}`}>{story.name}</a></li>
                        })}
                    </ul>
                </Col>
            </Row>
        </Container>)
    }

    const Cards = () => {

        const stories = storiesData.stories.map((story) => ({ ...story, type: "story" }));
        const characters = charactersData.characters.map((character) => ({ ...character, type: "character" }));
        const homepageContent = stories
            .concat(characters)
            .sort((a, b) => new Date(b.publicationDate) - new Date(a.publicationDate));

        return <Stack gap={4}>
            {homepageContent.map((content) => {
                return <ContentCard
                    key={content.guid}
                    title={content.name}
                    tag={<Tag content={content.type === "story" ? "Story" : "Bio"} type={content.type === "story" ? "story" : "bio"} />}
                    date={content.publicationDate}
                    content={content.type === "story" ? content.synopsis : getAppearsIn(content.stories)}
                    wordCount={content.type === "story" ? content.wordCount : undefined}
                    minutesToRead={content.type === "story" ? content.minutesToRead : undefined}
                    thumbnail={content.thumbnailFile === "" ? undefined : `${apiUrl}/${content.type === "story" ? "stories" : "characters"}/${content.guid}/thumbnail`}
                    fallbackThumbnail={content.type === "character" ? defaultProfilePic : undefined}
                    contentTags={getTags(content.tags)}
                    buttons={< Button
                        variant="primary"
                        onMouseDown={(e) => {
                            if (e.button === 1) { // If middle mouse button
                                content.type === "story" ?
                                    handleReadStory(content.guid, true) : handleReadBio(content.guid, true)
                            }

                        }}
                        onClick={() => content.type === "story" ?
                            handleReadStory(content.guid) : handleReadBio(content.guid)
                        }>
                        Read
                    </Button >}
                    resourceRedirect={`/${content.type === "story" ?
                        "stories" : "characters"}/${content.guid}`}
                />
            }
            )}
        </Stack >
    }

    return (
        <div className="App-header">
            <PageTitle content={"What's New"} />
            <Container className={"cards-container"}>
                <Row>
                    <Col>
                        <Cards />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Home;
