import './GroupIndexCard.scss'
import { Card, ListGroup, Collapse, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { ReactComponent as CollapseIcon } from '../../assets/icons/arrow-down-icon.svg';
import React from 'react';

function GroupIndexCard({ groups, groupActiveHandler }) {

    const mobileWidthThreshold = 992;

    const [activeGroup, setActiveGroup] = useState(-1);
    const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidthThreshold);
    const [open, setOpen] = useState(false);

    // set isMobile when page viewport width falls below threshold.mobileWidthThreshold
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < mobileWidthThreshold;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    const handleItemClick = (item) => {
        if (groupActiveHandler) {
            groupActiveHandler(item);
        }
        setActiveGroup(item);
    };

    const Groups = ({ groups, subLevel }) => {
        if (!subLevel) {
            subLevel = 0;
        }
        return (
            <ListGroup>
                {groups.map((group) => {
                    return (
                        <React.Fragment key={`group-${group.guid}`}>
                            <ListGroup.Item
                                className={`level-${subLevel}`}
                                onClick={() => handleItemClick(group.guid)}
                                active={activeGroup === group.guid}
                            >
                                {group.name}
                            </ListGroup.Item>
                            {group.children ? (
                                <Groups key={`sub-group-${group.guid}`} groups={group.children} subLevel={subLevel + 1} />
                            ) : (
                                <></>
                            )}
                        </React.Fragment>
                    );
                })}
            </ListGroup>
        );
    };


    return (
        <Card className={`${isMobile ? "groups-index-card-mobile" : "groups-index-card"}`} >
            {isMobile ? <><Card.Header onClick={() => setOpen(!open)} className={`${open ? "open" : "closed"}`}>
                <div>
                    <span>
                        Index
                    </span>
                    <Button
                        variant="link"
                        className={"btn-collapse"}
                        aria-controls="card-collapse"
                        aria-expanded={open}
                    >
                        <CollapseIcon className={`${open ? "open" : "closed"}`} />
                    </Button>
                </div>
            </Card.Header>
                <Collapse in={open}>
                    <div className={"card-collapse"}>
                        <Card.Body>
                            {isMobile ? <hr /> : <></>}
                            <Groups groups={groups} />
                        </Card.Body>
                    </div>
                </Collapse>
            </>
                : <>
                    <Card.Header >
                        Index
                    </Card.Header>
                    <Card.Body>
                        {isMobile ? <hr /> : <></>}
                        <Groups groups={groups} />
                    </Card.Body>
                </>
            }
        </Card >
    )
}

export default GroupIndexCard;