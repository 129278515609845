import './StoryReader.scss'
import PageTitle from '../../components/PageTitle/PageTitle';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContentReader from '../../components/ContentReader/ContentReader';
import AuthorNotes from '../../components/AuthorNotes/AuthorNotes';
import CommentSection from '../../components/CommentSection/CommentSection';

function StoryReader() {
    const [fileContents, setFileContents] = useState('');
    const [story, setStory] = useState('');
    const [settings, setSettings] = useState({
        fontSize: "md",
        fontStyle: "georgia",
        textAlign: "left"
    })

    const { id } = useParams();
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        window.scrollTo(0, 0);

        fetch(`${apiUrl}/stories/${id}/file`)
            .then(response => response.text())
            .then(data => setFileContents(data))

        fetch(`${apiUrl}/stories/${id}`)
            .then(response => response.json())
            .then(data => {
                document.title = `${data.name} | Ardeo's Doghouse`;
                setStory(data)
            })
    }, [apiUrl, id]);

    const handleChangeSettings = (setting, newValue) => {
        if (setting === "fontStyle") {
            setSettings(prevSettings => ({
                ...prevSettings,
                fontStyle: newValue
            }));
        } else if (setting === "fontSize") {
            setSettings(prevSettings => ({
                ...prevSettings,
                fontSize: newValue
            }));
        } else if (setting === "textAlign") {
            setSettings(prevSettings => ({
                ...prevSettings,
                textAlign: newValue
            }));
        }

    }

    const handlePostComment = (content, author, parentId) => {
        const comment = {
            content: content,
            parent: parentId ? parentId : null,
            author: author !== "" ? author : "Anonymous"
        }

        let url = `${apiUrl}/stories/${story.guid}/addComment`;

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(comment)
        }).then(() => {
            fetch(`${apiUrl}/stories/${id}`)
                .then(response => response.json())
                .then(data => setStory(data))
        })

    }

    return (
        <div className="App-header">
            <PageTitle content={story.name} onChangeReaderSettings={handleChangeSettings} settings={settings} />
            <div hidden={story.thumbnailFile === "" || story.thumbnailFile === undefined} className={"cover-div"}>
                <img
                    src={`${apiUrl}/stories/${story.guid}/thumbnail`}
                    alt={"story thumbnail"} />
            </div>
            <ContentReader content={fileContents} settings={settings} />
            <AuthorNotes
                content={story.authorNotes}
                author={story.author}
                date={story.publicationDate}
                wordCount={story.wordCount}
                minutesToRead={story.minutesToRead} />
            <CommentSection comments={story.comments?.sort((a, b) => new Date(b.publicationDate) - new Date(a.publicationDate))} onPostComment={handlePostComment} />
        </div>
    );
}

export default StoryReader;