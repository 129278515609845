export const tagSort = (a, b) => {
    const typeOrder = { Category: 0, Character: 1, Species: 2, Content: 3 };
    const aOrder = typeOrder[a.type];
    const bOrder = typeOrder[b.type];

    if (aOrder < bOrder) {
        return -1;
    } else if (aOrder > bOrder) {
        return 1;
    } else {
        if (a.name < b.name) {
            return -1;
        } else if (a.name > b.name) {
            return 1;
        } else {
            return 0;
        }
    }
};