
import { createSlice } from "@reduxjs/toolkit";

const apiUrl = process.env.REACT_APP_API_URL;

const initialState = {
    commissionsOpen: false,
};

export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        fetchSettings: (state, action) => {
            return { ...state, commissionsOpen: action.payload.find((s) => s.name === "commissions_open").value === "true" };
        }
    }
});

export const { fetchSettings } = settingsSlice.actions;

export default settingsSlice.reducer;

export const fetchSettingsAsync = (params) => dispatch => {

    let url = `${apiUrl}/settings`;

    fetch(url)
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Error: ' + response.status);
            }
        })
        .then(data => {
            dispatch(fetchSettings(data));
        })
        .catch(error => console.error(error));
};




