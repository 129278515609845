import '../BioCard/BioCard.scss';
import { Card, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import defaultProfilePic from '../../assets/images/default-profile-icon.jpg'

function BioCard({ character }) {

    const mobileWidthThreshold = 992;
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidthThreshold);


    // set isMobile when page viewport width falls below threshold.mobileWidthThreshold
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < mobileWidthThreshold;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    const handleReadBio = (id) => {
        navigate(`/characters/${id}`);
    }

    return (
        isMobile ?
            <a href={`/characters/${character.guid}`} className={"clickable-card"}>
                <Card className={"bio-card-mobile"} >
                    <Card.Img
                        variant="top"
                        src={`${apiUrl}/characters/${character.guid}/thumbnail`}
                        onError={(e) => { e.target.onerror = null; e.target.src = defaultProfilePic }} />
                    <Card.Body>
                        <Card.Title>{character.name}</Card.Title>
                        <Card.Subtitle>{character.synopsis}</Card.Subtitle>
                    </Card.Body>
                </Card>
            </a>
            :
            < Card className={"bio-card"} >
                <Card.Img
                    variant="top"
                    src={`${apiUrl}/characters/${character.guid}/thumbnail`}
                    onError={(e) => { e.target.onerror = null; e.target.src = defaultProfilePic }} />
                <Card.Body>
                    <Card.Title>{character.name}</Card.Title>
                    <Card.Subtitle>{character.synopsis}</Card.Subtitle>
                    <Button onClick={() => handleReadBio(character.guid)}>Read Bio</Button>
                </Card.Body>
            </Card >

    );
}

export default BioCard;