import { Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import './NewComment.scss'
import { useState } from "react";

function NewComment({ onPostComment, onCancel, autoFocus, placeholder }) {
    const [content, setContet] = useState("");
    const [author, setAuthor] = useState("");

    const max_comment_length = 5000;
    const max_author_name_length = 50;

    return (
        <>
            <Row>
                <Col>
                    <Form>
                        <Form.Group controlId="new-comment-text" className={"new-comment-textarea"}>
                            <Form.Control as="textarea" maxLength={max_comment_length} value={content} onChange={(e) => setContet(e.target.value)} autoFocus={autoFocus} rows={4} placeholder={placeholder ? placeholder : `Type your comment here.`} />
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
            <Row >
                <Col>
                    <Form className={"new-comment-author-form"}>
                        {onCancel !== undefined ? <Button
                            variant='secondary'
                            onClick={onCancel}
                            className={"cancel-reply"}>Cancel</Button>
                            : <></>}
                        <InputGroup className="mb-3 new-comment-author">
                            <Form.Control
                                aria-label="Example text with button addon"
                                aria-describedby="basic-addon1"
                                value={author}
                                maxLength={max_author_name_length}
                                onChange={(e) => setAuthor(e.target.value)}
                                placeholder={"Sign your name here."}
                            />
                            <Button onClick={() => {
                                onPostComment(content, author)
                                setContet("");
                            }
                            }
                                disabled={content.length === 0}
                                className={"btn-post-comment"}
                                variant="outline-secondary" id="button-addon1">
                                Post Comment
                            </Button>
                        </InputGroup>
                    </Form>
                </Col>
            </Row >
        </>
    )
}

export default NewComment;