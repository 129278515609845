import './Commissions.scss';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import PageSubTitle from '../../components/PageSubTitle/PageSubTItle';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCommissionsAsync } from '../../store/commissionsSlice';
import { fetchSettingsAsync } from '../../store/settingsSlice';

function Commissions() {

    const mobileWidthThreshold = 992;

    const dispatch = useDispatch();
    const commissionsData = useSelector(state => state.commissions);
    const commissionsOpen = useSelector(state => state.settings.commissionsOpen);
    const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidthThreshold);

    // set isMobile when page viewport width falls below threshold.mobileWidthThreshold
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < mobileWidthThreshold;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    useEffect(() => {
        dispatch(fetchCommissionsAsync());
        dispatch(fetchSettingsAsync());
        document.title = `Commissions | Ardeo's Doghouse`;
    }, [dispatch]);

    const defaultCalculatorPages = 10;

    const testimonialData = [
        {
            author: "HCliffordMcBride",
            link: "https://www.furaffinity.net/user/hcliffordmcbride",
            quote: "Ardeo writes great porn scenes. Fantastic even. But he doesn’t just stop at that, making some amazing character interaction, even down to very minor ones. One unironically might say they read it for the plot as well."
        },
        {
            author: "HalfDuplex",
            link: "https://www.furaffinity.net/user/halfduplex",
            quote: "I really appreciated the extra effort Ardeo put into the commission. A bulleted list outlining the story, being able to discuss ideas with him, make quick edits, and know exactly when your commission is done, really make it easy to share your biggest fantasies with a random dog (or guy?) on the internet."
        },
        {
            author: "Ticklishfennec",
            link: "https://www.furaffinity.net/user/ticklishfennec",
            quote: "The process for getting a commission was easy and to the point. I got to say exactly what I wanted and was able to receive updates if I asked. The story was better than I was expecting and would gladly request from him again."
        }
    ]

    const commissionThemeData = {
        acceptable: [
            "Bondage (of any kind)",
            "Feet/Paws",
            "Tickling",
            "Hypnosis",
            "Milking",
            "Edging",
            "Forced Orgasm",
            "Denial",
            "E-Stim/Electroplay",
            "Machines",
            "Pain/Torture",
            "Non-Con/Dub-Con",
            "Any Species or Gender",
        ],
        unacceptable: [
            "Scat/Coprophilia",
            "Bestiality/Zoophilia",
            "Cubs/Minors",
            "Diapers",
            "Watersports/Omorashi",
            "Excessive Musk",
        ]
    }

    const CommissionStatus = () => {
        return (<>
            <PageSubTitle content={"Status"}></PageSubTitle>
            <div className='commission-status-div'>
                {commissionsOpen ?
                    <span>Commissions are currently <b className={"open"}>OPEN</b>. <br></br> Note me on <a href={"https://www.furaffinity.net/user/ardeo955"} target="_blank" rel="noreferrer noopener">FurAffinity</a> or message me on Discord (Ardeo#9762) to claim a slot!</span>
                    : <span>Commissions are currently <b className={"closed"}>CLOSED</b>. <br></br> Check back another time or watch me on <a href={"https://www.furaffinity.net/user/ardeo955"} target="_blank" rel="noreferrer noopener">FurAffinity</a> to know when new slots open up!</span>}
            </div>
        </>);
    };

    const CurrentQueue = () => {

        const sortedCommissions = [...commissionsData?.commissions].sort((a, b) => a.place - b.place);

        return (<>
            <PageSubTitle content={"Current Queue"}></PageSubTitle>
            <Container className="current-queue-container">
                <Row>
                    <Col>
                        <div className="current-queue-table-wrapper">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Place</th>
                                        <th>Name</th>
                                        <th>Page Count</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedCommissions.map((slot) =>
                                        <tr key={slot.name}>
                                            <td>{slot.place}</td>
                                            <td>{slot.name}</td>
                                            <td>{slot.pageCount}</td>
                                            <td className={slot.status === "Done" ? "commission-done" : "commission-in-progress"}>
                                                {slot.status}</td>
                                        </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>)
    }

    const TestimonialCard = ({ author, quote, link }) => {
        return (<div className={"testimonial-card"}>
            <div>
                <p className={"testimonial-quote"}>"{quote}"</p>
                <span className={"testimonial-author"}>- <a href={link} rel="noreferrer" target="_blank">{author}</a> -</span>
            </div>
        </div>)
    }

    const Testimonials = () => {

        let carouselItems = [];

        testimonialData.forEach((t) => carouselItems.push(<Carousel.Item key={t.author.toLocaleLowerCase()}>
            <TestimonialCard author={t.author} quote={t.quote} link={t.link} />
        </Carousel.Item>));

        return (<>
            <PageSubTitle content={"Testimonials"}></PageSubTitle>
            {isMobile ? <Carousel interval={5000} prevIcon={<span />} nextIcon={<span />} className={"testimonials-container"}>{carouselItems}</Carousel> :
                <Container className={"testimonials-container"}>
                    <Row>
                        {testimonialData.map((t) =>
                            <Col key={t.author.toLocaleLowerCase()}>
                                <TestimonialCard author={t.author} quote={t.quote} link={t.link} />
                            </Col>)}
                    </Row>
                </Container>
            }
        </>)
    }

    const WhatICanDo = () => {
        return (<>
            <PageSubTitle content={"What I Can Do"}></PageSubTitle>
            <div className={"whaticando-container"}>
                <p>
                    I’m an erotic writer, so I mainly write <b>romance and fetish stories</b>, though I can also write SFW ones. I can work with a wide variety of settings from high fantasy to sci-fi and everything in-between!
                </p>
                <p>
                    I'll write about your <b>original characters</b>, or <b>fanfiction</b> based on other works like Pokémon or Zootopia, for instance. Otherwise, <b>I’m more than happy to come up with interesting characters for your story</b>. :)
                </p>
                <p>
                    For fetish stories, the list below shows what I will and won’t do. If you have any questions about topics not on the list, simply <b>message me on Discord (Ardeo#9762) or <a href={"https://www.furaffinity.net/user/ardeo955"} target="_blank" rel="noreferrer noopener">FurAffinity</a></b>. Don’t be shy, I don’t judge. ;)
                </p>
            </div>

            <div className={`dosanddonts-container ${isMobile ? "mobile" : ""}`}>
                <div className={"dosanddonts-list"}>
                    <div className={"dos-container"}>
                        I <span className={"highlight"}>WILL</span> write about:
                        <ul>
                            {commissionThemeData.acceptable.map((t) => <li key={t.toLocaleLowerCase()}>{t}</li>)}
                        </ul>
                    </div>
                    <div className={"donts-container"}>
                        I <span className={"highlight"}>WON'T</span> write about:
                        <ul>
                            {commissionThemeData.unacceptable.map((t) => <li key={t.toLocaleLowerCase()}>{t}</li>)}
                        </ul>
                    </div>
                </div>
            </div>
        </>);
    }

    const PricesAndPayment = () => {
        return (<>
            <PageSubTitle content={"Prices & Payment"}></PageSubTitle>
            <ul className={"prices-and-payment-list"}>
                <li>Stories are priced <b>according to their page count</b>.</li>
                <li>Price per page goes down as the number of pages goes up.</li>
                <li>There is <b>no page limit</b>.</li>
                <li>On average, each page will have about <b>500 words</b>.</li>
                <li>The page styling is: Times New Roman, 12pt, justified. Line spacing of 1.15pt and paragraph spacing of 5pt.</li>
                <li>Please note that stories can sometimes go a little over the agreed page total. I will not charge extra for this, of course.</li>
                <li>Payment must be made <b>in full</b> before I begin working on your story.</li>
                <li>I accept payment <b>only through PayPal</b>.</li>
            </ul>
            <div className={"price-table-container"}>
                {isMobile ?
                    <div className={"price-table"}>
                        <p>Under 5 pages ..................... 9$ per page.</p>
                        <p>From 5 to 9 pages ........... 8.5$ per page.</p>
                        <p>From 10 to 14 pages ........... 8$ per page.</p>
                        <p>From 15 to 19 pages ........ 7.5$ per page.</p>
                        <p>More than 20 pages ........... 7$ per page.</p>
                    </div> :
                    <div className={"price-table"}>
                        <p>Under 5 pages ....................................... 9$ per page.</p>
                        <p>From 5 to 9 pages ............................. 8.5$ per page.</p>
                        <p>From 10 to 14 pages ............................. 8$ per page.</p>
                        <p>From 15 to 19 pages .......................... 7.5$ per page.</p>
                        <p>More than 20 pages ............................ 7$ per page.</p>
                    </div>
                }
            </div>
        </>);
    }

    const PriceCalculator = () => {
        const calculateCost = useCallback((pages) => {

            if (pages < 5) {
                return pages * 9;
            } else if (pages >= 5 && pages < 10) {
                return pages * 8.5;
            } else if (pages >= 10 && pages < 15) {
                return pages * 8;
            } else if (pages >= 15 && pages < 20) {
                return pages * 7.5;
            } else {
                return pages * 7;
            }
        }, []);

        const [cost, setCost] = useState(calculateCost(defaultCalculatorPages));

        const handlePageChange = useCallback((e) => {
            e.preventDefault();
            setCost(calculateCost(e.target.value));
        }, [calculateCost]);

        return (
            <>
                <PageSubTitle content={"Price Calculator"}></PageSubTitle>
                <div className={"price-calculator-container"}>
                    <span>
                        A story with{" "}
                        <input
                            onInput={handlePageChange}
                            defaultValue={defaultCalculatorPages}
                            type="number"
                            id="pageNumber"
                            name="pageNumber"
                            min="1"
                            max="99"
                        />{" "}
                        pages will cost {cost}$.
                    </span>
                </div>
            </>
        );
    };

    const WhatYouGet = () => {
        return (<>
            <PageSubTitle content={"What You Get"}></PageSubTitle>
            <ul className={"whatyouget-list"}>
                <li>Before payment, I’ll write a rough outline of your story for your approval. <b>You can change anything about it</b>.</li>
                <li>Upon completion, I'll share the story with you so you may provide feedback and <b>request any changes</b>.</li>
                <li>You'll receive a .pdf file containing your story formatted as described above.</li>
                <li>If you have FurAffinity, I’ll also share a .txt file formatted for FurAffinity’s in-site reader.</li>
                <li><b>You may post your story wherever you like</b>. Though I ask that you credit me as the writer if you do so.</li>
                <li>I may post your story on my website and socials.</li>
                <li>Turnaround is usually <b>a few weeks at most</b>, depending on your place in the queue and the size of your story.</li>
                <li>Please keep in mind that, as much as I'd love to, I don't write full-time and work a regular 40h/week job. I am, however, committed to delivering your commission in a timely manner as best as I can.</li>
            </ul>
        </>);
    }

    const HowToOrder = () => {
        return (<>
            <PageSubTitle content={"How To Order"}></PageSubTitle>
            <ul className={"howtoorder-list"}>
                <li><b>Contact me</b> on Discord (Ardeo#9762) or <a href={"https://www.furaffinity.net/user/ardeo955"} target="_blank" rel="noreferrer noopener">FurAffinity</a> to claim a slot.</li>
                <li><b>Tell me about your idea! </b>It can be as detailed or as vague as you want. In either case, we'll work together to solidify it and I'll make an outline for you.</li>
                <li>Need suggestions? <b>I'm happy to come up with something for you</b> based on your interests. :)</li>
            </ul>

        </>);
    }

    return (
        <div className="App-header">
            <PageTitle content={"Commissions"} />
            <Container className={`commission-info-container ${isMobile ? "mobile" : ""}`}>
                <CommissionStatus />
                {commissionsData?.commissions.length > 0 ? <CurrentQueue /> : <></>}
                <Testimonials />
                <WhatICanDo />
                <PricesAndPayment />
                <PriceCalculator />
                <WhatYouGet />
                <HowToOrder />
            </Container >
        </div >
    )

}

export default Commissions;