import { Col, Row, Container, Form, Card, Button, Collapse } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import './PageTitle.scss'
import SearchBar from '../SearchBar/SearchBar';
import { ReactComponent as CollapseIcon } from '../../assets/icons/arrow-down-icon.svg';

function PageTitle({ content, onSearch, onChangeReaderSettings, settings }) {

    const mobileWidthThreshold = 992;

    const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidthThreshold);
    const [open, setOpen] = useState(false);

    // set isMobile when page viewport width falls below threshold.mobileWidthThreshold
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < mobileWidthThreshold;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    const Options = () => {
        return <Col className={`settings-col ${isMobile ? "mobile" : ""}`}>
            <Form>
                <div className={"settings-option"}>
                    <Form.Label>Font</Form.Label>
                    <Form.Select
                        onChange={(e) => onChangeReaderSettings("fontStyle", e.target.value)}
                        value={settings.fontStyle}
                        className={"setting-select"}>
                        <option value="georgia">Georgia</option>
                        <option value="verdana">Verdana</option>
                        <option value="opendyslexic">Open Dyslexic</option>
                        <option value="wingdingsreview">WD Review</option>
                    </Form.Select>
                </div>
                <div className={"settings-option"}>
                    <Form.Label>Alignment</Form.Label>
                    <Form.Select
                        onChange={(e) => onChangeReaderSettings("textAlign", e.target.value)}
                        value={settings.textAlign}
                        className={"setting-select"}>
                        <option value="left">Left</option>
                        <option value="justified">Justified</option>
                    </Form.Select>
                </div>
                <div className={"settings-option"}>
                    <Form.Label>Size</Form.Label>
                    <Form.Select
                        onChange={(e) => onChangeReaderSettings("fontSize", e.target.value)}
                        value={settings.fontSize}
                        className={"setting-select"}>
                        <option value="sm">Small</option>
                        <option value="md">Medium</option>
                        <option value="lg">Large</option>
                        <option value="x-lg">Extra Large</option>
                    </Form.Select>
                </div>
            </Form>
        </Col>
    }

    return (
        isMobile ?
            <Container className={"page-title-container-mobile"}>
                {onChangeReaderSettings !== undefined ?
                    <Row className={"page-title-row-mobile"}>
                        <Col>
                            <Card className={"mobile-reader-settings-card"} >
                                <Card.Header onClick={() => setOpen(!open)} className={`${open ? "open" : "closed"}`}>
                                    <div>
                                        <span>
                                            Text Settings
                                        </span>
                                        <Button
                                            variant="link"
                                            className={"btn-collapse"}
                                            aria-controls="card-collapse"
                                            aria-expanded={open}
                                        >
                                            <CollapseIcon className={`${open ? "open" : "closed"}`} />
                                        </Button>
                                    </div>
                                </Card.Header>
                                <Collapse in={open}>
                                    <div className={"card-collapse"}>
                                        <Card.Body>
                                            <hr></hr>
                                            {Options()}
                                        </Card.Body>
                                    </div>
                                </Collapse>
                            </Card >
                        </Col>
                    </Row>
                    : <></>}
                <Row className={"page-title-row-mobile"}>
                    <Col>
                        <div className={"page-title-content-mobile"}>
                            <span>{content}</span>
                        </div>
                    </Col>
                </Row>
                {onSearch ?
                    <Row>
                        <Col className={"search-bar-col-mobile"}>
                            <SearchBar onSearch={onSearch} />
                        </Col>
                    </Row>
                    :
                    <></>}
            </Container > :
            <Container
                className={`page-title-container-web ${onChangeReaderSettings !== undefined && !isMobile ? "has-settings" : ""}`}>
                <Row className={"page-title-row-web"}>
                    <Col className={"page-title-content-web d-flex align-items-end"}>
                        <span>{content}</span>
                    </Col>
                    {onSearch ?
                        <Col xs={3} className={"search-bar-col"}>
                            <SearchBar onSearch={onSearch} />
                        </Col>
                        :
                        <></>}
                    {onChangeReaderSettings ?
                        Options()
                        :
                        <></>}
                </Row>
            </Container >

    )
}

export default PageTitle;