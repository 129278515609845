import { createSlice } from "@reduxjs/toolkit";

const apiUrl = process.env.REACT_APP_API_URL;

const initialState = {
    stories: [],
    groups: []
};

export const storiesSlice = createSlice({
    name: "stories",
    initialState,
    reducers: {
        fetchStories: (state, action) => {
            return { ...state, stories: action.payload };
        },
        fetchGroups: (state, action) => {
            return { ...state, groups: action.payload };
        }
    }
});

export const { fetchStories, fetchGroups } = storiesSlice.actions;

export default storiesSlice.reducer;

export const fetchStoriesAsync = (params) => dispatch => {

    let url = `${apiUrl}/stories?sort_by=date&order_by=desc`;

    if (params?.groupId !== undefined) {
        url += `&groupId=${params.groupId}`;
    }

    if (params?.searchQuery !== undefined) {
        url += `&searchQuery=${encodeURIComponent(params?.searchQuery)}`;
    }

    fetch(url)
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Error: ' + response.status);
            }
        })
        .then(data => {
            dispatch(fetchStories(data));
        })
        .catch(error => console.error(error));
};

export const fetchGroupsAsync = () => dispatch => {
    fetch(`${apiUrl}/groups`)
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Error: ' + response.status);
            }
        })
        .then(data => {
            dispatch(fetchGroups(data));
        })
        .catch(error => console.error(error));
};





