import './SearchBar.scss'
import { Form, InputGroup } from 'react-bootstrap';
import { useState } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/icons/search-icon.svg';


function SearchBar({ onSearch }) {

    const [searchTerm, setSearchTerm] = useState('');

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        onSearch(searchTerm);
    }

    return (
        <Form className="search-bar-form" onSubmit={handleSubmit}>
            <InputGroup>
                <Form.Control type="text" value={searchTerm} placeholder="Search here..." onChange={handleChange} />
                <InputGroup.Text className={"search-icon"} onClick={handleSubmit}><SearchIcon /></InputGroup.Text>
            </InputGroup>
        </Form >
    );
}

export default SearchBar;