import { Container, Row } from 'react-bootstrap';
import './Logo.scss'
import { useState, useEffect } from 'react';
import taglinesFile from '../../assets/taglines.txt'

function Logo() {
    const [randomLine, setRandomLine] = useState("");

    useEffect(() => {
        fetch(taglinesFile)
            .then(response => response.text())
            .then(text => {
                const lines = text.split('\n');
                const randomIndex = Math.floor(Math.random() * lines.length);
                const randomLine = lines[randomIndex];
                setRandomLine(randomLine);
                localStorage.setItem('randomLine', randomLine);
            })
    }, []);

    return (
        <Container className={"logo-container"}>
            <Row className={"website-name"}>Ardeo's Doghouse</Row>
            <Row className={"website-tagline"}>{randomLine}</Row>
        </Container>
    );
}

export default Logo;