import { Col, Container, Row } from 'react-bootstrap';
import './AuthorNotes.scss'
import { marked } from 'marked';
import { useEffect, useState } from 'react';
import { formatDate, formatDateShort } from '../../utils/dateTimeUtils';


function AuthorNotes({ content, author, date, wordCount, minutesToRead }) {

    const mobileWidthThreshold = 992;
    const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidthThreshold);

    // set isMobile when page viewport width falls below threshold.mobileWidthThreshold
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < mobileWidthThreshold;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    const html = content ? marked.parse(
        content.replace(/^[\u200B\u200C\u200D\u200E\u200F\uFEFF]/, "")
    ) : "";

    const getInfoString = () => {
        let info = formatDate(date);

        if (wordCount) {
            info += `  /  ${wordCount} words`
        }

        if (minutesToRead) {
            info += `  /  ${minutesToRead} minutes to read`
        }

        return info;
    }

    const getInfoStringMobile = () => {
        let info = formatDateShort(date);

        if (wordCount) {
            info += `  /  ${wordCount} words`
        }

        if (minutesToRead) {
            info += `  /  ${minutesToRead} minutes to read`
        }

        return info;
    }

    return (
        <Container className={`author-comments-container ${isMobile ? "mobile" : ""}`}>
            <span>Author's Notes</span>
            <Row>
                <Col>
                    <span className={"info"}>{isMobile ? getInfoStringMobile() : getInfoString()}</span>
                    <div className={"author-comments-div"} dangerouslySetInnerHTML={{ __html: html }} />
                    <span className={"author-signature"}>- {author}</span>
                </Col>
            </Row>
        </Container>
    )
}

export default AuthorNotes;