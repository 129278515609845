import './App.scss';
import NavigationBar from './components/NavigationBar/NavigationBar';
import Footer from './components/Footer/Footer';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Stories from './pages/Stories/Stories';
import Characters from './pages/Characters/Characters';
import StoryReader from './pages/StoryReader/StoryReader';
import BiographyReader from './pages/BiographyReader/BiographyReader';
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Cookies from 'js-cookie';
import { Button } from 'react-bootstrap';
import Commissions from './pages/Commissions/Commissions';

function App() {

  const [modalIsOpen, setModalIsOpen] = useState(!Cookies.get('hasSeenModal'));

  useEffect(() => {
    // Disable page scroll if modal open and enable if modal closed.
    document.body.style.overflow = modalIsOpen ? 'hidden' : 'auto';
  }, [modalIsOpen])

  // Set this to prevent screen readers from reading what's beneath the modal while it's active.
  Modal.setAppElement('#root');

  return (
    <div className="App">
      <NavigationBar />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/stories" element={<Stories />} />
          <Route path="/stories/:id" element={<StoryReader />} />
          <Route path="/characters" element={<Characters />} />
          <Route path="/characters/:id" element={<BiographyReader />} />
          <Route path="/commissions" element={<Commissions />} />
        </Routes>
      </Router>

      <Modal className={"content-warning-modal"}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Adult Content Warning Modal"
      >
        <h2>Adult Content Warning</h2>
        <div className={"modal-content"}>
          <p>This website contains <bold>explicit references to sexual themes</bold> intended for <bold>adults only</bold>.</p>
          <p>By entering this website, you confirm that you are 18 years old or older.</p>
          <p>If you are a minor, <bold>leave immediately</bold>.</p>
        </div>
        <Button variant='primary' onClick={() => {
          setModalIsOpen(false);
          Cookies.set('hasSeenModal', true, { expires: 1 });
        }}>Enter Ardeo's Doghouse</Button>
        <div className={"empty-space"}></div>
      </Modal>
      <Footer />
    </div >);
}

export default App;
