import './ContentReader.scss'
import { marked } from 'marked';
import {
    Container, Col, Row
} from 'react-bootstrap';
import { useEffect, useState } from 'react';

function ContentReader({ content, settings }) {

    const mobileWidthThreshold = 992;
    const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidthThreshold);

    // set isMobile when page viewport width falls below threshold.mobileWidthThreshold
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < mobileWidthThreshold;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    const html = marked.parse(
        content.replace(/^[\u200B\u200C\u200D\u200E\u200F\uFEFF]/, "")
    )

    const GetFontSize = () => {
        switch (settings.fontSize) {
            case "sm":
                return "reader-settings-font-xs";
            case "md":
                return "reader-settings-font-md";
            case "lg":
                return "reader-settings-font-lg";
            case "x-lg":
                return "reader-settings-font-x-lg";
            default:
                return "reader-settings-font-md";
        }
    }

    const GetFontStyle = () => {
        switch (settings.fontStyle) {
            case "georgia":
                return "reader-settings-font-georgia";
            case "verdana":
                return "reader-settings-font-verdana";
            case "opendyslexic":
                return "reader-settings-font-opendyslexic";
            case "wingdingsreview":
                return "reader-settings-font-wingdingsreview";
            default:
                return "reader-settings-font-georgia";
        }
    }

    const GetTextAlignment = () => {
        switch (settings.textAlign) {
            case "left":
                return "reader-settings-align-left";
            case "justified":
                return "reader-settings-align-justify";
            default:
                return "reader-settings-align-left";
        }
    }

    return <Container className={`story-container ${isMobile ? "mobile" : ""}`}>
        <Col>
            <Row>
                <div className={`story-div ${GetFontSize()} ${GetFontStyle()} ${GetTextAlignment()}`} dangerouslySetInnerHTML={{ __html: html }} />
            </Row>
            <hr className={"content-end-seperator"} />
        </Col>
    </Container>
};

export default ContentReader;