import './ContentCard.scss'
import { Card, Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { formatDate, formatDateShort } from '../../utils/dateTimeUtils';

function ContentCard({
    tag,
    title,
    date,
    content,
    contentTags,
    buttons,
    thumbnail,
    resourceRedirect,
    wordCount,
    minutesToRead,
    fallbackThumbnail }) {

    const mobileWidthThreshold = 992;

    const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidthThreshold);
    const [imageError, setImageError] = useState(false);

    // set isMobile when page viewport width falls below threshold.mobileWidthThreshold
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < mobileWidthThreshold;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    const getInfoString = () => {
        let info = formatDate(date);

        if (wordCount) {
            info += `  /  ${wordCount} words`
        }

        if (minutesToRead) {
            info += `  /  ${minutesToRead} minutes to read`
        }

        return info;
    }

    const getInfoStringMobile = () => {
        let info = formatDateShort(date);

        if (wordCount) {
            info += `  /  ${wordCount} words`
        }

        if (minutesToRead) {
            info += `  /  ${minutesToRead} minutes to read`
        }

        return info;
    }

    return (
        isMobile ? <a href={resourceRedirect} className={"clickable-card"}>
            <Card className={"content-card card-mobile"} >
                <Card.Header>
                    {tag}
                    {title}
                    <br />
                    <span className="info">
                        {isMobile ? getInfoStringMobile() : getInfoString()}
                    </span>
                </Card.Header>
                <Card.Body>
                    {thumbnail && !imageError ?
                        <Col className={"img-col"}>
                            <img
                                src={thumbnail}
                                alt={"thumbnail"}
                                onError={(e) => {
                                    if (fallbackThumbnail) {
                                        e.target.onerror = null;
                                        e.target.src = fallbackThumbnail
                                    } else {
                                        setImageError(true);
                                    }
                                }}
                            ></img>
                        </Col> : <></>
                    }
                    {content}
                    {contentTags ?
                        <div className={"tags-container"}>
                            <hr className={"line-break"} />
                            {contentTags}
                        </div> : <></>
                    }
                </Card.Body>
            </Card>
        </a>
            :
            <Card className={"content-card"}>
                <Card.Header>
                    {tag}
                    {title}
                    <span className="info">
                        {getInfoString()}
                    </span>
                </Card.Header>
                <Card.Body>
                    <Container>
                        <Row>
                            {thumbnail && !imageError ?
                                <Col className={"img-col"}>
                                    <img
                                        src={thumbnail}
                                        alt={"thumbnail"}
                                        onError={(e) => {
                                            if (fallbackThumbnail) {
                                                e.target.onerror = null;
                                                e.target.src = fallbackThumbnail
                                            } else {
                                                setImageError(true);
                                            }
                                        }}
                                    ></img>
                                </Col> : <></>
                            }
                            <Col className={"content-col"}>
                                {content}
                                {contentTags ?
                                    <div className={"tags-container"}>
                                        {contentTags}
                                    </div> : <></>
                                }
                            </Col>
                            <Col xs={2} className={"button-col align-self-end"}>
                                {buttons}
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
    );
}

export default ContentCard;