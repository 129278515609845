import './BiographyReader.scss'
import PageTitle from "../../components/PageTitle/PageTitle";
import ContentReader from '../../components/ContentReader/ContentReader';
import CommentSection from '../../components/CommentSection/CommentSection';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Col, Container, Row } from 'react-bootstrap';
import defaultProfilePic from '../../assets/images/default-profile-icon.jpg'

function BiographyReader() {
    const { id } = useParams();
    const apiUrl = process.env.REACT_APP_API_URL;
    const mobileWidthThreshold = 992;
    const [fileContents, setFileContents] = useState('');
    const [character, setCharacter] = useState('');
    const [settings, setSettings] = useState({
        fontSize: "md",
        fontStyle: "georgia"
    })

    const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidthThreshold);

    // set isMobile when page viewport width falls below threshold.mobileWidthThreshold
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < mobileWidthThreshold;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    useEffect(() => {
        window.scrollTo(0, 0);

        fetch(`${apiUrl}/characters/${id}/description`)
            .then(response => response.text())
            .then(data => setFileContents(data))

        fetch(`${apiUrl}/characters/${id}`)
            .then(response => response.json())
            .then(data => {
                document.title = `${data.name} | Ardeo's Doghouse`;
                setCharacter(data);
            })
    }, [apiUrl, id]);

    const handleChangeSettings = (setting, newValue) => {
        if (setting === "fontStyle") {
            setSettings(prevSettings => ({
                ...prevSettings,
                fontStyle: newValue
            }));
        } else if (setting === "fontSize") {
            setSettings(prevSettings => ({
                ...prevSettings,
                fontSize: newValue
            }));
        }

    }

    const handlePostComment = (content, author, parentId) => {
        const comment = {
            content: content,
            publicationDate: new Date().toISOString(),
            parent: parentId ? parentId : null,
            author: author !== "" ? author : "Anonymous"
        }

        let url = `${apiUrl}/characters/${character.guid}/addComment`;

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(comment)
        }).then(() => {
            fetch(`${apiUrl}/characters/${id}`)
                .then(response => response.json())
                .then(data => setCharacter(data))
        })
    }

    const AppearsIn = ({ stories }) => {
        return (<Container className={`appears-in-container ${isMobile ? "mobile" : ""}`}>
            <Row>
                <Col>
                    <span>Appears In</span>
                    <ul>
                        {stories?.map((story) => {
                            return <li key={`appears-in-${story.guid}`}><a href={`/stories/${story.guid}`}>{story.name}</a></li>
                        })}
                    </ul>
                </Col>
            </Row>
        </Container>)
    }

    return (
        <div className="App-header">
            <PageTitle content={character.name} onChangeReaderSettings={handleChangeSettings} settings={settings} />
            <div className={"bio-profile-div"}>
                <img
                    src={`${apiUrl}/characters/${character.guid}/thumbnail`}
                    alt={"character-profile"}
                    onError={(e) => { e.target.onerror = null; e.target.src = defaultProfilePic }} />
            </div>
            <ContentReader content={fileContents} settings={settings} />
            <AppearsIn stories={character.stories} />
            <CommentSection comments={character.comments?.sort((a, b) => new Date(b.publicationDate) - new Date(a.publicationDate))} onPostComment={handlePostComment} />
        </div >
    );
}

export default BiographyReader;