import { Container, Nav, Navbar } from 'react-bootstrap';
import Logo from '../Logo/Logo'
import './NavigationBar.scss'

function NavigationBar() {
    return (
        <Navbar expand="lg">
            <Navbar.Brand><Logo /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Container className="d-flex justify-content-center">
                    <Nav>
                        <Nav.Link className='navbar-link' href="/">Home</Nav.Link>
                        <Nav.Link className='navbar-link' href="/stories">Stories</Nav.Link>
                        <Nav.Link className='navbar-link' href="/characters">Characters</Nav.Link>
                        <Nav.Link className='navbar-link' href="/commissions">Commissions</Nav.Link>
                    </Nav>
                </Container>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default NavigationBar;