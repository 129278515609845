import { configureStore } from "@reduxjs/toolkit"
import storiesSlice from "./storiesSlice";
import charactersSlice from "./charactersSlice";
import commissionsSlice from "./commissionsSlice";
import settingsSlice from "./settingsSlice";

export const store = configureStore({
    reducer: {
        stories: storiesSlice,
        characters: charactersSlice,
        commissions: commissionsSlice,
        settings: settingsSlice
    }
});