import { Container } from 'react-bootstrap';
import './CommentSection.scss'
import { useEffect, useState } from 'react';
import Comment from '../Comment/Comment';
import NewComment from '../NewComment/NewComment';

function CommentSection({ comments, onPostComment }) {

    const mobileWidthThreshold = 992;
    const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidthThreshold);

    // set isMobile when page viewport width falls below threshold.mobileWidthThreshold
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < mobileWidthThreshold;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    const GetComments = ({ comments }) =>
        comments?.map((comment) => {
            return <Comment comment={comment} onPostComment={onPostComment} />
        })

    return (
        <Container className={`comment-section-container ${isMobile ? "mobile" : ""}`}>
            <span>Comments</span>
            <NewComment
                onPostComment={onPostComment}
                autoFocus={false} />
            <GetComments comments={comments} />
        </Container>
    )
};


export default CommentSection;