import PageTitle from '../../components/PageTitle/PageTitle';
import './Stories.scss'
import Tag from '../../components/Tag/Tag';
import ContentCard from '../../components/ContentCard/ContentCard';
import {
    Button,
    Stack,
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import GroupIndexCard from '../../components/GroupIndexCard/GroupIndexCard';
import { tagSort } from '../../utils/tagUtils';
import { useSelector, useDispatch } from 'react-redux';
import { fetchStoriesAsync, fetchGroupsAsync } from '../../store/storiesSlice';
import { useNavigate } from 'react-router-dom';

function Stories() {

    const mobileWidthThreshold = 992;
    const apiUrl = process.env.REACT_APP_API_URL;

    const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidthThreshold);
    const [activeGroup, setActiveGroup] = useState(-1);
    const [searchQuery, setSearchQuery] = useState("");
    const storiesData = useSelector(state => state.stories);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // set isMobile when page viewport width falls below threshold.mobileWidthThreshold
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < mobileWidthThreshold;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    useEffect(() => {
        dispatch(fetchStoriesAsync());
        dispatch(fetchGroupsAsync());
        document.title = `Stories | Ardeo's Doghouse`;
    }, [dispatch]);

    useEffect(() => {
        var params = {};

        if (activeGroup !== -1)
            params.groupId = activeGroup

        if (searchQuery !== "")
            params.searchQuery = searchQuery

        dispatch(fetchStoriesAsync(params));
    }, [dispatch, activeGroup, searchQuery])

    const handleSearch = (e) => setSearchQuery(e);

    const handleReadStory = (storyId, newTab) => {
        if (newTab) {
            window.open(`${window.location.origin}/stories/${storyId}`, "_blank", "noopener,noreferrer");
            return;
        }
        navigate(`/stories/${storyId}`);
    }
    const getGroups = () => [{ id: -1, name: "All Stories" }].concat(storiesData.groups);

    const getTags = (tags) => {
        const filteredAndSortedTags = [...tags].filter((tag) => tag.type !== "None").sort(tagSort);
        return filteredAndSortedTags.map((tag) => (
            <Tag key={`tag-${tag.guid}`} content={tag.name} type={tag.type.toLowerCase()} />))
    }

    const handleMouseDown = (event, guid) => {
        if (event.button === 1) { // If middle mouse button
            handleReadStory(guid, true);
        }
    };

    const Cards = () => {
        return <Stack gap={4}>
            {storiesData.stories.map((story) => {
                return <ContentCard
                    key={`story-card-${story.guid}`}
                    title={story.name}
                    date={story.publicationDate}
                    content={story.synopsis}
                    wordCount={story.wordCount}
                    minutesToRead={story.minutesToRead}
                    thumbnail={story.thumbnailFile === "" ? undefined : `${apiUrl}/stories/${story.guid}/thumbnail`}
                    contentTags={getTags(story.tags)}
                    buttons={<Button variant="primary" onMouseDown={(e) => handleMouseDown(e, story.guid)} onClick={() => handleReadStory(story.guid)}>Read</Button>}
                    resourceRedirect={`/stories/${story.guid}`}
                />
            }
            )}
        </Stack>
    }

    return (
        <div className="App-header">
            <PageTitle content={"Stories"} onSearch={handleSearch} />
            <Container className={"cards-container"}>
                {isMobile ? <Row>
                    <Col>
                        <GroupIndexCard groupActiveHandler={setActiveGroup} groups={getGroups()}></GroupIndexCard>
                    </Col>
                </Row> : <></>
                }
                <Row>
                    <Col>
                        <Cards />
                    </Col>
                    {isMobile ?
                        <></> :
                        <Col xs={3} className={"group-index-col"}>
                            <GroupIndexCard groupActiveHandler={setActiveGroup} groups={getGroups()}></GroupIndexCard>
                        </Col>
                    }
                </Row>
            </Container>
        </div>
    );
}

export default Stories;
