import './Footer.scss';
import chibiDeo from '../../assets/images/ardeo.svg';
import chibiClawd from '../../assets/images/clawd.svg';

function Footer() {
    const hideVersion = process.env.NODE_ENV === "production";
    const version = process.env.REACT_APP_VERSION;

    return (
        <footer>
            <div className={"footer-text"}>
                <div>
                    <img src={chibiClawd} alt="ChibiClawd" />
                    <img src={chibiDeo} alt="ChibiDeo" />
                </div>
                <div className={"footer-dedication"}>
                    <span>
                        From Clawd and Ardeo, with <span className={"footer-dedication-highlight"}>love</span>.
                    </span>
                </div>
                <div>
                    <span>
                        <a href={"https://www.furaffinity.net/user/clawdy"} target="_blank" rel="noreferrer noopener">Clawd's Furaffinity</a></span>
                    {" | "}
                    <a href={"https://www.furaffinity.net/user/ardeo955"} target="_blank" rel="noreferrer noopener">Ardeo's Furaffinity</a>
                </div>
                <div>
                    {new Date().getFullYear()}
                </div>
                <span hidden={hideVersion} className={"footer-website-version"}>v{version} - in {process.env.NODE_ENV}</span>
            </div>
        </footer >
    );
}

export default Footer;