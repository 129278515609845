import { createSlice } from "@reduxjs/toolkit";

const apiUrl = process.env.REACT_APP_API_URL;

const initialState = {
    characters: []
};

export const charactersSlice = createSlice({
    name: "characters",
    initialState,
    reducers: {
        fetchCharacters: (state, action) => {
            return { ...state, characters: action.payload };
        }
    }
});

export const { fetchCharacters } = charactersSlice.actions;

export default charactersSlice.reducer;

export const fetchCharactersAsync = () => dispatch => {
    fetch(`${apiUrl}/characters`)
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Error: ' + response.status);
            }
        })
        .then(data => {
            dispatch(fetchCharacters(data));
        })
        .catch(error => console.error(error));
};





