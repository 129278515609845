import PageTitle from '../../components/PageTitle/PageTitle';
import './Characters.scss'
import { Container, Row, Col, Stack } from 'react-bootstrap';
import BioCard from '../../components/BioCard/BioCard';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCharactersAsync } from '../../store/charactersSlice';

function Characters() {

    const mobileWidthThreshold = 992;

    const dispatch = useDispatch();
    const charactersData = useSelector(state => state.characters);
    const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidthThreshold);

    // set isMobile when page viewport width falls below threshold.mobileWidthThreshold
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < mobileWidthThreshold;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    useEffect(() => {
        dispatch(fetchCharactersAsync());
        document.title = `Characters | Ardeo's Doghouse`;
    }, [dispatch]);

    const BioCards = () => {
        const cards = isMobile ?
            <Stack>
                {charactersData.characters.map((character) =>
                    <BioCard key={`bio-card-${character.guid}`} character={character} />
                )}
            </Stack> :
            <Row xs={1} md={4} className="g-5">
                {charactersData.characters.map((character) =>
                    <Col key={`bio-card-${character.guid}`}>
                        <BioCard character={character} />
                    </Col>)}
            </Row>

        return cards;
    }

    return (
        <div className="App-header">
            <PageTitle content={"Character Bios"} />
            <Container className={"bio-card-container"}>
                <BioCards />
            </Container >
        </div >
    );
}

export default Characters;
