import { Col, Container, Row } from "react-bootstrap";
import "./PageSubTitle.scss"

function PageSubTitle({ content }) {

    return (
        <Container className="page-subtitle-container">
            <Row className="page-subtitle-row">
                <Col>
                    <div className={"page-subtitle-content"}>
                        <span>{content}</span>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default PageSubTitle;