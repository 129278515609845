import { createSlice } from "@reduxjs/toolkit";

const apiUrl = process.env.REACT_APP_API_URL;

const initialState = {
    commissions: [],
};

export const commissionsSlice = createSlice({
    name: "commissions",
    initialState,
    reducers: {
        fetchCommissions: (state, action) => {
            return { ...state, commissions: action.payload };
        }
    }
});

export const { fetchCommissions } = commissionsSlice.actions;

export default commissionsSlice.reducer;

export const fetchCommissionsAsync = (params) => dispatch => {

    let url = `${apiUrl}/commissions`;

    fetch(url)
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Error: ' + response.status);
            }
        })
        .then(data => {
            dispatch(fetchCommissions(data));
        })
        .catch(error => console.error(error));
};




