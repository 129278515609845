import './Comment.scss'
import {
    Col, Row, Button
} from 'react-bootstrap';
import { useState, useEffect } from 'react';
import NewComment from '../NewComment/NewComment';
import { formatDate, formatDateShort } from '../../utils/dateTimeUtils';
import { ReactComponent as ReplyIcon } from '../../assets/icons/reply-icon.svg';

function Comment({ comment, onPostComment }) {

    const mobileWidthThreshold = 992;
    const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidthThreshold);
    const [replyBoxActive, setReplyBoxActive] = useState(false);

    // set isMobile when page viewport width falls below threshold.mobileWidthThreshold
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < mobileWidthThreshold;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    const handlePostComment = (content, author) => {
        onPostComment(content, author, comment.guid)
    }

    return (<Col>
        <Row className={"comment-card"}>
            <div>
                <h5><span>
                    {comment.author} {isMobile ?
                        <></>
                        :
                        <span className="pub-date">
                            on {formatDate(comment.creationTime)}
                        </span>}
                </span>
                    <Button className={"btn-reply"} onClick={() => setReplyBoxActive(true)} variant="link"><ReplyIcon />Reply</Button>
                </h5>
                {isMobile ? <span className="pub-date mobile">
                    {formatDateShort(comment.creationTime)}
                </span> : <></>}
            </div>
            <div>
                <p>
                    {comment.content}
                </p>
            </div>

            {comment.replies?.length > 0 ? <>
                <div className='child-comment-container'>
                    <span className={"replies"}>Replies</span>
                    {comment.replies?.map((child, index) => {
                        return <div className={"child-comment"}>
                            <hr className={`comment-seperator ${index === 0 ? "first" : ""}`}></hr>
                            <div>
                                <div>
                                    <h5>{child.author} {isMobile ?
                                        <></>
                                        :
                                        <span className="pub-date">
                                            on {formatDate(child.creationTime)}
                                        </span>}
                                    </h5>
                                    {isMobile ? <span className="pub-date mobile">
                                        {formatDateShort(child.creationTime)}
                                    </span> : <></>}
                                </div>
                            </div>
                            <div>
                                <div>
                                    <p>
                                        {child.content}
                                    </p>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </>
                : <></>}
        </Row> {
            replyBoxActive ?
                <NewComment
                    placeholder={`Type your reply to ${comment.author} here.`}
                    onCancel={() => setReplyBoxActive(false)}
                    onPostComment={handlePostComment}
                    autoFocus={true} />
                : <></>
        }
    </Col >);
}

export default Comment;